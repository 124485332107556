import { Form, Input, message, Modal } from "antd";

import TextArea from "antd/es/input/TextArea";
import { postService, updateService } from "../../../../api/service/api";
import { useEffect, useState } from "react";
import CkEditorCustome from "../../common/CkEditorCustome";

const AddService = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const data = props.data;
  const [content, setContent] = useState(props?.data?.description);

  useEffect(() => {
    if(props.data && props.isVisible){
      form.setFieldsValue(props.data);
    }else{
      form.resetFields()
    }
  }, [form, props.data, props.isVisible]);

  const onFinish = (values) => {
    setLoading(true);
    const value = { ...values, description: content};
    if (data) {
      updateService(data.id, value)
        .then((res) => {
          form.resetFields();
          message.success("Sửa dịch vụ thành công");
          props.onCancel();
        })
        .finally(() => setLoading(false));
    } else {
      postService(value)
        .then((res) => {
          form.resetFields();
          message.success("Thêm mới dịch vụ thành công");
          props.onCancel();
        })
        .finally(() => setLoading(false));
    }
  };
  const onFinishFailed = (errorInfo) => {};

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
  };


  return (
    <Modal
      title={props.data ? "Cập nhật dịch vụ" : "Thêm mới dịch vụ"}
      centered
      open={props.isVisible}
      onOk={() => form.submit()}
      onCancel={props.onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
      width={1000}
      okButtonProps={{
        className: "bg-admin-btn-primary",
      }}
      okText={props.data ? "Cập nhật" : "Tạo mới"}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        <Form.Item
          label="Tên dịch vụ"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập tên dịch vụ!",
            },
          ]}
        >
          <Input placeholder="Nhập tên dịch vụ" />
        </Form.Item>
        <CkEditorCustome
          label={"Mô tả dịch vụ"}
          description={props?.data?.description}
          setContent={handleChange}
        />
      </Form>
    </Modal>
  );
};
export default AddService;
