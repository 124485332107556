import React from "react";
import Div from "../../div";
import Image1 from "../../../asset/logo-and-service/image11.jpg";
import Image2 from "../../../asset/logo-and-service/vision-image.jpg";

function AboutVision() {
  const coreValues = [
    {
      title: "Tâm",
      content:
        "Lấy đạo đức và tâm của con người làm gốc rễ để phát triển bền vững trong lĩnh vực y tế.",
    },
    {
      title: "Trí",
      content: "Điều trị bằng trí tuệ và khối óc của các Bác Sĩ Chuyên Khoa.",
    },
    {
      title: "Tín",
      content:
        "Coi trọng và bảo vệ uy tín với khách hàng về chất lượng, tiến độ và các cam kết trong dịch vụ y tế.",
    },
    {
      title: "Tầm",
      content:
        "Với tầm nhìn và sứ mệnh được xác định rõ ràng giúp thu hút nhân tài đồng hành, cống hiến cùng Doanh Nghiệp. Đầu tư khoa học, hiện đại, bài bản mang tầm vóc Quốc Tế.",
    },
  ];

  const vision = [
    {
      content:
        "Phát triển hệ thống nha khoa  implant với quy mô - trí tuệ mang tầm quốc tế với phong cách phục vụ chuyên nghiệp hàng đầu cho người Việt ",
    },
    {
      content:
        "Tiên phong số 1 về công nghệ trong điều trị, thẩm mỹ nha khoa tại Việt Nam và trên toàn Thế giới.",
    },
  ];

  const mission = [
    { content: "Chăm sóc, bảo vệ và gìn giữ từng đơn vị răng của người Việt." },
    {
      content: "Sử dụng công nghệ đến từ hoa kỳ.",
    },
    {
      content:
        "Cam kết mang đến cho các khách hàng sự hài lòng về chất lượng dịch vụ cũng như chất lượng sản phẩm.",
    },
  ];

  return (
    <Div>
      <Div className="my-container py-[30px] lg:py-[80px]">
        <Div>
          <h1 className={styles.aboutPageTitle}>
            GIÁ TRỊ CỐT LÕI - TẦM NHÌN - SỨ MỆNH
          </h1>
        </Div>

        <article>
          <Div className="lg:flex">
            <Div className="lg:basis-[65%]">
              <Div
                className={`${styles.sectionBox} mt-[50px] after:bottom-[-12.5px] after:bg-p-color`}
              >
                <h2 className={styles.sectionTitle}>GIÁ TRỊ CỐT LÕI</h2>

                <ul className=" duration-300 group-hover:text-white">
                  {coreValues.map((e, i) => {
                    return (
                      <li key={i} className="mb-[10px]">
                        <p className="text-sm md:text-base lg:text-lg">
                          <span className="font-semibold">{e.title}: </span>
                          {e.content}
                        </p>
                      </li>
                    );
                  })}
                </ul>

                <Div className={`${styles.slideBg} bottom-0`}></Div>
              </Div>

              <Div className="w-full h-[629px] mt-[20px] md:mt-0">
                <img className={styles.image} src={Image1} alt="" />
              </Div>
            </Div>

            <Div className="lg:basis-[35%]">
              <Div
                className={`${styles.sectionBox} mt-[50px] after:bottom-[-12.5px] after:bg-p-color`}
              >
                <Div>
                  <h2 className={styles.sectionTitle}>TẦM NHÌN</h2>
                </Div>

                <ul className="pl-[10px] text-sm duration-300 group-hover:text-white md:text-base lg:text-lg">
                  {vision.map((e, i) => {
                    return (
                      <li key={i} className="list-disc mb-[5px] last:mb-0">
                        {e.content}
                      </li>
                    );
                  })}
                </ul>

                <Div className={`${styles.slideBg} bottom-0`}></Div>
              </Div>

              <Div className="w-full h-[280px] mt-[30px] md:mt-0">
                <img className={styles.image} src={Image2} alt="" />
              </Div>

              <Div>
                <Div
                  className={`${styles.sectionBox} mt-[40px] after:top-[-12.5px] after:bg-white lg:mt-[20px]`}
                >
                  <Div>
                    <h2 className={styles.sectionTitle}>SỨ MỆNH</h2>
                  </Div>

                  <ul className="ml-[10px] text-sm duration-300 group-hover:text-white md:text-base lg:text-lg">
                    {mission.map((e, i) => {
                      return (
                        <li key={i} className="list-disc mb-[5px] last:mb-0">
                          {e.content}
                        </li>
                      );
                    })}
                  </ul>

                  <Div className={`${styles.slideBg} top-0`}></Div>
                </Div>
              </Div>
            </Div>
          </Div>
        </article>
      </Div>
    </Div>
  );
}

const styles = {
  aboutPageTitle:
    "uppercase text-p-color text-2xl font-bold text-center md:text-3xl lg:text-4xl",
  sectionBox:
    "overflow-hidden relative group p-[10px] md:p-[30px] after:hidden md:after:block after:content-[''] after:absolute after:left-[50%] after:translate-x-[-50%] after:rotate-[45deg] after:w-[25px] after:h-[25px] hover:after:bg-white after:duration-500",
  sectionTitle:
    "uppercase text-p-color text-xl font-bold text-center mb-[20px] duration-300 group-hover:text-white md:text-2xl",
  slideBg:
    "w-full h-0 absolute left-[50%] translate-x-[-50%] bottom-0 bg-p-color -z-10 duration-300 group-hover:h-full",
  image: "w-full h-full object-cover object-center",
};

export default AboutVision;
