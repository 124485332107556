import "./App.css";
import "./css/tailwind-config/tailwind-output.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import "firebase/storage";
import Login from "./page/login";
import MainLayout from "./page/layout";
import RegisterAdvise from "./page/admin/register-advise";
import Service from "./page/admin/service";
import PriceService from "./page/admin/price-service";
import ContactComments from "./page/admin/contact-comments";
import CryptoJS from "crypto-js";
import { Button, Result } from "antd";
import { useEffect, useState } from "react";
import ProductDentistry from "./page/admin/product-dentistry";
import ContentWeb from "./page/admin/service/web";
import Content from "./components/main/content-web";
import Layout from "./components/layout";
import MainHome from "./components/main/home";
import About from "./components/main/about";
import UiService from "./components/main/service";
import ServicePrice from "./components/main/service-price";
import DentalProduct from "./components/main/dental-product";
import Contact from "./components/main/contact";
import NotFound from "./components/not-found";
import News from "./components/main/news";
import NewsAdmin from "./page/admin/news";
import CustomerDocument from "./page/admin/customer-document";
import Customer from "./components/main/customer";

function App() {
  const navigate = useNavigate();
  const [checkLogin, setCheckLogin] = useState(false);
  const storedData = localStorage.getItem("datakey");
  let decryptedData = null;
  if (storedData) {
    const bytes = CryptoJS.AES.decrypt(
      storedData,
      process.env.REACT_APP_SECRET_KEY
    );
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  useEffect(() => {
    setCheckLogin(
      storedData &&
        decryptedData?.username === process.env.REACT_APP_USERNAME &&
        decryptedData?.password === process.env.REACT_APP_PASSWORD
    );
  }, [storedData]);

  useEffect(() => {
    if (!window.location.pathname.includes("admin")) {
      localStorage.clear();
    }
  }, [window.location.href, window.location.pathname]);

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<MainHome />} />
          <Route path='about' element={<About />} />
          <Route path='service' element={<UiService />} />
          <Route path='content-web' element={<Content />} />
          <Route path='service-price' element={<ServicePrice />} />
          <Route path='dental-product' element={<DentalProduct />} />
          <Route path='contact' element={<Contact />} />
          <Route path='news' element={<News />} />
          <Route path='customer' element={<Customer />} />
        </Route>
        <Route path='*' element={<NotFound />} />

        <Route
          path='/admin/*'
          element={
            checkLogin ? (
              <MainLayout />
            ) : (
              <Result
                status='403'
                title={<div className='text-black'>403</div>}
                subTitle={
                  <div className='text-black'>
                    Sorry, you are not authorized to access this page.
                  </div>
                }
                extra={
                  <Button
                    type='primary'
                    onClick={() => navigate("/login")}
                    className='bg-admin-btn-primary'
                  >
                    Login now
                  </Button>
                }
              />
            )
          }
        >
          <Route path={"register-advise"} element={<RegisterAdvise />} />
          <Route path={"service"} element={<Service />} />
          <Route path={"price-service"} element={<PriceService />} />
          <Route path={"contact-comments"} element={<ContactComments />} />
          <Route path={"product-dentistry"} element={<ProductDentistry />} />
          <Route path={"content-web"} element={<ContentWeb />} />
          <Route path={"news"} element={<NewsAdmin />} />
          <Route path={"customer-documents"} element={<CustomerDocument />} />
        </Route>
        <Route path='/login' element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;

// import React from "react";
// import NotFound from "./components/not-found";
// import { Route, Routes } from "react-router-dom";

// function App() {
//   return (
//     <Routes>
//       <Route path='*' element={<NotFound />} />
//     </Routes>
//   );
// }

// export default App;
