import React, { useEffect, useState } from "react";
import Div from "../div";
import { MdLocationPin } from "react-icons/md";
import { FaPhoneAlt, FaChevronRight } from "react-icons/fa";
import { createSearchParams, useNavigate } from "react-router-dom";
import Logo from "../../asset/logo-and-service/logo-removebg.png";
import { getAllService } from "../../api/service/api";
import FooterBackground from "../../asset/background/footer-bg.jpg";
import ZaloImg from "../../asset/logo-and-service/zalo.jpg";
import TiktokImg from "../../asset/logo-and-service/tiktok.png";

function Footer() {
  const [service, setSevice] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllService()
      .then((res) => {
        const data = res.data;

        data && setSevice(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <footer>
      <div
        style={{
          backgroundImage: `linear-gradient(#f7f9fbdd,#f7f9fbdd),url(${FooterBackground})`,
        }}
        className='bg-cover bg-center'
      >
        <Div className='my-container py-[30px] flex flex-col md:grid grid-cols-12 gap-[40px] lg:justify-between md:flex-row md:gap-[10px] lg:gap-[20px]'>
          <Div className='flex flex-col gap-[20px] col-span-12 md:col-span-3'>
            <Div className='w-full'>
              <img className='w-[280px] object-cover' src={Logo} alt='' />
            </Div>

            <Div>
              <ul className='flex flex-col gap-[10px]'>
                <li className='flex items-center justify-start gap-[10px]'>
                  <span className='text-p-color text-lg'>
                    <MdLocationPin />
                  </span>

                  <p>
                    <span className='font-semibold'></span>Số nhà LK02-14 và
                    LK02-15 Đường Lê Lợi, Phường Yết Kiêu - Thành phố Hạ Long -
                    Tỉnh Quảng Ninh
                  </p>
                </li>

                <li className='flex items-center justify-start gap-[10px]'>
                  <span className='text-p-color text-lg'>
                    <FaPhoneAlt />
                  </span>

                  <p>032 903 2266</p>
                </li>
              </ul>
            </Div>
          </Div>

          <Div className=' col-span-12 md:col-span-3'>
            <Div className='mb-[15px]'>
              <p className='uppercase text-xl font-bold'>DỊCH VỤ NHA KHOA</p>
            </Div>

            <Div>
              <ul className='flex flex-col gap-[15px]'>
                {service.length > 0 &&
                  service.map((e, i) => {
                    return (
                      <li key={i} className='group'>
                        <Div
                          onClick={() =>
                            navigate({
                              pathname: "/content-web",
                              search: `?${createSearchParams({
                                content: e.id,
                                type: "dental-service",
                              }).toString()}`,
                            })
                          }
                          className='flex items-center justify-between bg-white px-[18px] py-[12px] group-hover:bg-p-color group-hover:text-white duration-500'
                        >
                          <span className='font-semibold'>{e.name}</span>
                          <span>
                            <FaChevronRight />
                          </span>
                        </Div>
                      </li>
                    );
                  })}
              </ul>
            </Div>
          </Div>

          <Div className='flex flex-col gap-[30px] col-span-12 md:col-span-3 overflow-hidden'>
            <Div>
              <Div className='mb-[15px]'>
                <p className='uppercase text-xl font-bold'>FANPAGE</p>
              </Div>

              <Div className='w-full'>
                <iframe
                  src='https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61553717916759%26mibextid%3DzOiqff&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId'
                  allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
                ></iframe>
              </Div>
              <Div className='flex '>
                <Div>
                  <a href='https://zalo.me/0329032266'>
                    <img src={ZaloImg} height={10} width={35} />
                  </a>
                </Div>
                <Div>
                  <a href='https://www.tiktok.com/@nkqt.american?_t=8lGY9u6ZRPF&_r=1'>
                    <img src={TiktokImg} height={10} width={50} />
                  </a>
                </Div>
              </Div>
            </Div>

            <Div>
              <Div className='mb-[15px]'>
                <p className='text-xl font-bold'>Google Maps</p>
              </Div>

              <Div>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3725.8268434039214!2d107.083185675042!3d20.959467780672593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314a5900514ff43d%3A0x70c243524b4a1e25!2zTmhhIEtob2EgUXXhu5FjIFThur8gQW1lcmljYW4!5e0!3m2!1svi!2sus!4v1714570636417!5m2!1svi!2sus'
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              </Div>
            </Div>
          </Div>
          <Div className='flex flex-col gap-[30px] col-span-12 md:col-span-3 overflow-hidden'>
            <Div>
              <Div className='mb-[15px]'>
                <p className='uppercase text-xl font-bold'>ZALO</p>
              </Div>

              <Div className='w-full'>
                <a
                  href='https://zalo.me/0329032266'
                  className='inline-block w-full'
                >
                  <img
                    src={ZaloImg}
                    className='w-full md:h-[130px] object-cover object-left'
                  />
                </a>
              </Div>
            </Div>
            <Div>
              <Div className='mb-[15px]'>
                <p className='uppercase text-xl font-bold'>TIKTOK</p>
              </Div>

              <Div className='w-full'>
                <a
                  href='https://www.tiktok.com/@nkqt.american?_t=8lGY9u6ZRPF&_r=1'
                  className='inline-block w-full'
                >
                  <img
                    src={TiktokImg}
                    className='w-full md:h-[140px] object-cover object-left'
                  />
                </a>
              </Div>
            </Div>
          </Div>
        </Div>
      </div>

      <Div className='bg-p-color'>
        <Div className='my-container py-[10px] flex flex-col gap-[5px] items-center justify-between text-white md:flex-row md:gap-0'>
          <p>Copyright &copy; Nha Khoa Quốc Tế American</p>
          <p>
            Designed and implemented &copy;
            <a
              className='ml-5'
              href='https://zalo.me/0386631231'
              target='_blank'
              rel='noopener noreferrer'
            >
              NexGen Software
            </a>
          </p>
        </Div>
      </Div>
    </footer>
  );
}

export default Footer;
