import React from "react";
import Div from "../div";
import Slider from "react-slick";
import { GrNext, GrPrevious } from "react-icons/gr";
import "./reactSlickStyle.css";
import ReviewBackground from "../../asset/background/review-bg.jpg";
import Customer1 from "../../asset/logo-and-service/customer-1.jpg";
import Customer2 from "../../asset/logo-and-service/customer-2.jpg";
import Customer3 from "../../asset/logo-and-service/customer-3.jpg";
import Customer4 from "../../asset/logo-and-service/customer-4.jpg";
import Customer5 from "../../asset/logo-and-service/customer-5.jpg";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <Div
      onClick={onClick}
      className='absolute right-[-10px] top-[48%] md:top-[58%] text-white bg-p-color py-[8px] pl-[10px] pr-[8px] rounded-full z-10 cursor-pointer lg:text-2xl lg:right-[-60px]'
    >
      <GrNext />
    </Div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <Div
      onClick={onClick}
      className='absolute left-[-10px] top-[48%] md:top-[58%] text-white bg-p-color py-[8px] pl-[8px] pr-[10px] rounded-full z-10 cursor-pointer lg:text-2xl lg:left-[-60px]'
    >
      <GrPrevious />
    </Div>
  );
};

function ReviewCarousel() {
  const reviews = [
    {
      avatar: Customer1,
      reviewerName: "Trần Bích Thủy",
      reviewerJob: "Kinh doanh",
      description:
        "Bây giờ thì mình đã tự tin selfie dù vẫn đang trong quá trình niềng răng bởi nó cũng khá là thời trang nha. Thanks Nha Khoa Quốc Tế American.",
    },
    {
      avatar: Customer4,
      reviewerName: "Trần Linh",
      reviewerJob: "Kinh doanh",
      description:
        "Nụ cười tự tin rạng rỡ sẽ mang lại nhiều may mắn trong cuộc sống và Nha Khoa Quốc Tế American đã giúp tôi có được điều đó",
    },
    {
      avatar: Customer2,
      reviewerName: "Trang Vũ",
      reviewerJob: "Kinh doanh",
      description:
        "Chất lượng dịch vụ tuyệt vời, đi khám răng nhưng tưởng như lạc vào quán cafe sang trọng",
    },
    {
      avatar: Customer3,
      reviewerName: "Minh Tú",
      reviewerJob: "Kinh doanh",
      description:
        "Chất lượng của Nha Khoa Quốc Tế American thì miễn bàn luôn, 10 điểm",
    },
    {
      avatar: Customer5,
      reviewerName: "Anh Minh",
      reviewerJob: "Kinh doanh",
      description:
        "Trải nghiệm dịch vụ lấy cao răng chỉ với 19k nhưng kết quả tuyệt vời. Mọi người hãy đến trải nghiệm nhé",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => {
      return <Div className='w-[10px] h-[10px] rounded-full'></Div>;
    },
  };

  return (
    <Div className={styles.container}>
      <Slider {...settings}>
        {reviews.map((e, i) => {
          return (
            <Div key={i} className='relative h-[270px] pt-[45px]'>
              <Div className={styles.avatarBox}>
                <img className={styles.avatar} src={e.avatar} alt='' />
              </Div>

              <Div
                style={{
                  backgroundImage: `linear-gradient(#1d48a699,#1d48a699),url(${ReviewBackground})`,
                }}
                className={styles.background}
              >
                <Div className={styles.textBox}>
                  <p className='text-2xl font-bold'>{e.reviewerName}</p>
                  <p className='font-semibold'>{e.reviewerJob}</p>
                  <p>{e.description}</p>
                </Div>
              </Div>
            </Div>
          );
        })}
      </Slider>
    </Div>
  );
}

const styles = {
  container:
    "my-container w-[95%] lg:w-[60%] absolute top-[180px] left-[50%] translate-x-[-50%] md:top-[140px]",
  avatarBox:
    "w-[90px] h-[90px] border-[6px] border-white rounded-full overflow-hidden absolute top-0 left-[50%] translate-x-[-50%]",
  avatar: "w-full h-full object-cover object-center",
  background: "bg-center h-full pt-[60px] flex items-center justify-center",
  textBox: "text-white text-center pb-[15px] w-[90%] md:w-[80%] lg:w-[70%]",
};

export default ReviewCarousel;
