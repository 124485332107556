import React, { useEffect, useRef, useState } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";
import Image1 from "../../asset/logo-and-service/DSC_8732.jpg";
import Image2 from "../../asset/logo-and-service/DSC_8813.jpg";
import Image3 from "../../asset/logo-and-service/DSC_8832.jpg";
// import Image4 from "../../asset/logo-and-service/Doan-Ket.jpg";
import Div from "../div";

function Carousel() {
  const [active, setActive] = useState(1);
  const intervalRef = useRef(null);

  const carouselBannerImgs = [Image1, Image2, Image3];

  const next = () => {
    clearInterval(intervalRef.current);
    setActive(active < carouselBannerImgs.length ? active + 1 : 1);
  };

  const prev = () => {
    clearInterval(intervalRef.current);
    setActive(active > 1 ? active - 1 : carouselBannerImgs.length);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setActive((prev) => (prev = active < 3 ? active + 1 : 1));
    }, 8000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [active]);

  return (
    <Div className='mx-[20px] max-h-[700px] overflow-hidden relative md:mx-0'>
      <Div className='w-full px-[15px] h-[700px] flex justify-between items-center text-2xl md:text-3xl md:px-0'>
        <Div onClick={next} className={styles.nextBtn}>
          <GrPrevious />
        </Div>

        <Div onClick={prev} className={styles.prevBtn}>
          <GrNext />
        </Div>
      </Div>
      {carouselBannerImgs.map((e, i) => {
        return (
          <Div key={i}>
            <img
              className={`${styles.images} ${
                active === i + 1 ? "opacity-100" : "opacity-0"
              }`}
              src={e}
              alt=''
            />
          </Div>
        );
      })}
    </Div>
  );
}

const styles = {
  nextBtn:
    "rounded-full pl-[5px] pr-[8px] py-[8px] md:pl-[10px] md:pr-[15px] md:py-[30px] bg-black/20 md:rounded-none md:rounded-e-md hover:bg-black/40 cursor-pointer",
  prevBtn:
    "rounded-full pl-[8px] pr-[5px] py-[8px] md:pl-[10px] md:pr-[15px] md:py-[30px] bg-black/20 md:rounded-none md:rounded-s-md hover:bg-black/40 cursor-pointer",
  images:
    "w-full h-[700px] object-cover object-center absolute left-[50%] translate-x-[-50%] transition-opacity duration-1000 top-0 -z-10",
};

export default Carousel;
