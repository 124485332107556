import React, { useEffect, useState } from "react";
import NavigateBanner from "../../common/navigate-banner";
import Div from "../../div";
import PreviewBox from "../../common/preview-box";
import { getAllNews } from "../../../api/news/api";
import { Spin } from "antd";

function News() {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getAllNews()
      .then((res) => {
        const data = res.data;
        data && setDataSource(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Div>
        <Spin style={{ display: "flex", justifyContent: "center" }} />
      </Div>
    );
  }

  return (
    <main>
      <section>
        <NavigateBanner navigatePage={"Tin tức"} />
      </section>

      <section>
        <Div>
          <PreviewBox previewList={dataSource} pageSize={6} type={"news"} />
        </Div>
      </section>
    </main>
  );
}

export default News;
