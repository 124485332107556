import {
    Button,
    Col,
    Empty,
    Modal,
    Row,
    Space,
    Spin,
    Table,
    message,
  } from "antd";
  import { columnCustomerDocuments, renderStatus } from "../common/columns";
  import React, { useEffect, useState } from "react";
  import dayjs from "dayjs";
  import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
  import AddCustomerDocument from "./addCustomerDocument";
  import {
    deleteCustomerDocument,
    getAllCustomerDocuments,
  } from "../../../api/customer-documents/api";
  
  const CustomerDocument = () => {
    const locale = {
      emptyText: (
        <Space direction="vertical" align="center" style={{ marginTop: "20px" }}>
          <Empty description={"Chưa có thông tin khách hàng"} />
        </Space>
      ),
    };
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [isVisible, setIsvisible] = useState(false);
    const [data, setData] = useState(null);
  
    useEffect(() => {
      if (!isVisible) getData();
    }, [isVisible]);
  
    function getData() {
      setLoading(true);
      getAllCustomerDocuments()
        .then((res) => {
          setDataSource(mapDataSource(res?.data));
        })
        .finally(() => setLoading(false));
    }
  
    const mapDataSource = (values) => {
      return values?.map((item) => ({
        ...item,
        key: item.id,
        createDate: dayjs(item.createdAt).format("DD-MM-YYYY"),
        status: renderStatus(item.status),
        menu: (
          <>
            <Row gutter={12} justify={"end"} align={"middle"}>
              <Col>
                <EditOutlined
                  onClick={() => {
                    setData(item);
                    setIsvisible(true);
                  }}
                />
              </Col>
              <Col>
                <DeleteOutlined onClick={() => confirmDelete(item.id)} />
              </Col>
            </Row>
          </>
        ),
      }));
    };
  
    function confirmDelete(id) {
      Modal.confirm({
        title: "Xác nhận",
        content: "Bạn có chắc là muốn xóa khách hàng này không?",
        okText: <div style={{ color: "white" }}>Xóa</div>,
        okButtonProps: {
          className: "bg-admin-btn-primary",
        },
        onOk: () => {
          setLoading(true);
          deleteCustomerDocument(id).then((res) => {
            message.success("Xóa thành công");
            getData();
          });
        },
      });
    }
  
    return (
      <div>
        <div style={{ padding: "10px" }}>
          <Button type="dashed" onClick={() => setIsvisible(true)}>
            Tạo mới khách hàng
          </Button>
        </div>
        <Spin tip="Xin vui lòng chờ..." spinning={loading}>
          <Table
            columns={columnCustomerDocuments}
            dataSource={dataSource}
            locale={locale}
          />
        </Spin>
        <AddCustomerDocument
          isVisible={isVisible}
          onCancel={() => {
            setIsvisible(false);
            setData(null);
          }}
          data={data}
        />
      </div>
    );
  };
  export default CustomerDocument;