import React, { useState } from "react";
import Div from "../div";
import { FaBars, FaPhoneAlt } from "react-icons/fa";
import { GiPositionMarker } from "react-icons/gi";
import { IoMdAlarm } from "react-icons/io";
import { IoSearchOutline, IoClose } from "react-icons/io5";
import Logo from "../../asset/logo-and-service/logo-removebg.png";
import ToggleNav from "./ToggleNav";
import Navbar from "./Navbar";
import ModalAdvise from "../advise/modalAdvise";
import { Link } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [openAdviseModal, setOpenAdviseModal] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  return (
    <header>
      <ModalAdvise isOpen={{ openAdviseModal, setOpenAdviseModal }} />

      <Div
        className={`fixed w-full h-full bg-[rgba(75,163,218,0.70)] z-50 flex items-center justify-center duration-500 ${
          openSearch ? "visible opacity-100" : "invisible opacity-0"
        }`}
      >
        <Div
          onClick={() => setOpenSearch(false)}
          className='absolute top-[30px] right-[30px] text-4xl text-white'
        >
          <button>
            <IoClose />
          </button>
        </Div>

        <Div className='flex items-center justify-between border-b-2 border-white w-[400px] text-white pl-[15px]'>
          <input
            type='text'
            placeholder='Nhập vào từ khóa tìm kiếm...'
            className='bg-transparent outline-none flex-1 placeholder:text-white/75 placeholder:text-center'
          />
          <span className='text-3xl mb-[5px] px-[10px] cursor-pointer'>
            <IoSearchOutline />
          </span>
        </Div>
      </Div>

      <section className='py-[15px]'>
        <Div className={styles.container}>
          <Div className='max-w-[100px] md:max-w-[180px] lg:max-w-[150px]'>
            <Link to={"/"} className='blog w-full'>
              <img className='w-full object-cover' src={Logo} alt='' />
            </Link>
          </Div>

          <Div className='flex justify-between lg:flex-1 lg:pb-2 md:w-full'>
            <Div className='hidden md:flex justify-start gap-[5px] md:flex-1 md:justify-center'>
              <Div className='flex items-center gap-[10px]'>
                <Div className='text-4xl text-p-color lg:text-5xl'>
                  <IoMdAlarm />
                </Div>

                <Div>
                  <p className='text-p-color font-bold'>Thời gian làm việc</p>
                  <p>Từ 7h30-19h hàng ngày</p>
                </Div>
              </Div>

              <Div className='flex items-center gap-[10px]'>
                <Div className='text-3xl text-p-color lg:text-5xl'>
                  <GiPositionMarker />
                </Div>

                <Div>
                  <p className='text-p-color font-bold'>
                    Số nhà LK02 - 14 và LK02 - 15 Đường Lê Lợi
                  </p>
                  <p>Phường Yết Kiêu - TP.Hạ Long - Tỉnh Quảng Ninh</p>
                </Div>
              </Div>

              <Div className='flex items-center gap-[10px]'>
                <Div className='text-3xl text-p-color lg:text-4xl'>
                  <FaPhoneAlt />
                </Div>

                <Div>
                  <p className='text-p-color font-bold'>032 903 2266</p>
                  <p>Hotline 24/7</p>
                </Div>
              </Div>
            </Div>

            <Div className='flex items-center justify-between gap-2'>
              <Div onClick={() => setOpenAdviseModal(true)}>
                <a href='#' className={styles.adviseBtn}>
                  Tư vấn miễn phí
                </a>
              </Div>

              <span className='md:hidden' onClick={() => setIsOpen(true)}>
                <FaBars className='text-p-color text-[1.5rem] mt-[0.3rem] cursor-pointer' />
              </span>
            </Div>
          </Div>
        </Div>
      </section>

      <section className='hidden md:block'>
        <Navbar />
      </section>

      <section>
        <ToggleNav isOpen={isOpen} setIsOpen={setIsOpen} />
      </section>
    </header>
  );
}

const styles = {
  container:
    "my-container flex items-center justify-between md:flex-col lg:flex-row lg:gap-0 lg:justify-center",
  searchIcon:
    "text-p-color text-[1.5rem] mt-[0.3rem] cursor-pointer md:text-[1.8rem] lg:text-[2.3rem] lg:mt-0",
  adviseBtn:
    "bg-p-color bg-right bg-gradient-to-r from-[#3866c9_50%] to-[#1d48a6_0] text-white bg-[length:200%_200%] hover:bg-left duration-300 inline-block font-semibold px-[10px] pt-[8px] pb-[9px] rounded-[50px] text-[11px] md:pt-[14px] md:pb-[17px] lg:pt-[10px] lg:pb-[12px] lg:text-base lg:px-[20px]",
};

export default Header;
