import React, { useEffect, useState } from "react";
import Div from "../../div";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { getAllContentWeb } from "../../../api/service/api-content";

function ContentWebContact() {
  const [servicesContent, setServiceContent] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllContentWeb()
      .then((res) => {
        const data = res.data;
        let filterData = [];

        data &&
          data.forEach((e, i) => {
            if (
              e.active === 1 &&
              !filterData.find(
                (item) => item.dentalServicesId === e.dentalServicesId
              )
            ) {
              filterData = [
                ...filterData,
                {
                  dentalServicesId: e.dentalServicesId,
                  dentalServicesName: e.dentalServicesName,
                },
              ];
            }
          });

        setServiceContent(filterData);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Div>
      <Div className='my-container pb-[50px]'>
        <Div>
          <h2 className='text-2xl text-p-color font-bold mb-[10px]'>
            Nha khoa Quốc Tế American
          </h2>

          <Div>
            <ul className='flex flex-col gap-[8px]'>
              <li>
                <p className='text-base'>
                  <span>Địa chỉ: </span>
                  <Link
                    to={
                      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d66924.4812726528!2d107.05380165643196!3d20.935101997258887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314a5900514ff43d%3A0x70c243524b4a1e25!2zTmhhIEtob2EgUXXhu5FjIFThur8gQW1lcmljYW4!5e0!3m2!1svi!2s!4v1712291096808!5m2!1svi!2s"
                    }
                    target='_blank'
                    className='text-base text-p-color'
                  >
                    Số nhà LK02-14 và LK02-15 Đường Lê Lợi - Phường Yết Kiêu -
                    TP.Hạ Long - Tỉnh Quảng Ninh
                  </Link>
                </p>
              </li>

              <li>
                <p className='text-base'>
                  <span>Hotline: </span>
                  <span>032 903 2266</span>
                </p>
              </li>

              <li>
                <p className='text-base'>
                  <span>Website chính thức: </span>
                  <Link to={"/"} className='text-base text-p-color'>
                    nhakhoaquocteamerican.vn
                  </Link>
                </p>
              </li>

              <li>
                <p className='text-base'>
                  <span>Facebook: </span>
                  <Link
                    to={
                      "https://www.facebook.com/profile.php?id=61553717916759"
                    }
                    target='_blank'
                    className='text-base text-p-color'
                  >
                    Nha khoa Quốc Tế American
                  </Link>
                </p>
              </li>

              <li>
                <p className='text-base'>
                  <span>Chat trực tiếp với bác sĩ chuyên khoa: </span>
                  <Link
                    to={"https://www.m.me/61553717916759"}
                    target='_blank'
                    className='text-base text-p-color'
                  >
                    facebook
                  </Link>
                </p>
              </li>

              {servicesContent.map((e, i) => {
                return (
                  <li key={i}>
                    <Div
                      onClick={() =>
                        navigate({
                          pathname: "/content-web",
                          search: `?${createSearchParams({
                            service: e.dentalServicesId,
                            name: e.dentalServicesName,
                          }).toString()}`,
                        })
                      }
                      className='text-base text-p-color cursor-pointer'
                    >
                      Tìm hiểu thêm về dịch vụ {e.dentalServicesName}
                    </Div>
                  </li>
                );
              })}
            </ul>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

export default ContentWebContact;
