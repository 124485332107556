import React from "react";
import Div from "../../div";
import { FaChevronRight } from "react-icons/fa6";
import BannerImage from "../../../asset/logo-and-service/navigate-banner-1.jpg";

function NavigateBanner({ navigatePage, parentPage }) {
  return (
    <Div>
      <Div
        style={{ backgroundImage: `url(${BannerImage})` }}
        className={styles.banner}
      >
        <Div className={styles.container}>
          <h2 className='text-xl font-bold lg:text-4xl'>{navigatePage}</h2>

          <Div>
            <p className={styles.pages}>
              <span>Trang chủ</span>

              {parentPage && (
                <>
                  <span className='text-[8px] lg:text-[12px] lg:mt-[1px]'>
                    <FaChevronRight />
                  </span>
                  <span>{parentPage}</span>
                </>
              )}

              <span className='text-[8px] lg:text-[12px] lg:mt-[1px]'>
                <FaChevronRight />
              </span>
              <span>{navigatePage}</span>
            </p>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  banner: "bg-cover bg-no-repeat bg-right-bottom h-[300px] lg:h-[400px]",
  container: "my-container h-full flex flex-col justify-center lg:gap-[25px]",
  pages: "flex items-center gap-[5px] lg:text-lg lg:gap-[8px]",
};

export default NavigateBanner;
