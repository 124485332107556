import React from "react";
import Div from "../../div";
import { MdLocationOn } from "react-icons/md";
import { FaPhoneAlt, FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

function ContactInfo() {
  const contact = [
    {
      icon: <MdLocationOn />,
      info: "Số nhà LK02 - 14 và LK02 - 15 Đường Lê Lợi",
    },
    {
      icon: <FaPhoneAlt />,
      info: "032 903 2266",
    },
    {
      icon: <FaCalendarAlt />,
      info: "7h30-19h hàng ngày",
    },
  ];

  return (
    <Div>
      <Div className='my-container py-[50px] md:py-0'>
        <Div className={styles.box}>
          {contact.map((e, i) => {
            return (
              <Div key={i} className={styles.itemBoxs}>
                <Div>
                  <Link to={""} className={styles.icons}>
                    {e.icon}
                  </Link>
                </Div>

                <p className='text-lg'>{e.info}</p>
              </Div>
            );
          })}
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  box: "flex flex-wrap items-center justify-around gap-[25px] md:h-[160px]",
  itemBoxs:
    "basis-[40%] flex flex-col gap-[15px] items-center justify-center md:flex-1",
  icons: "text-5xl text-p-color",
};

export default ContactInfo;
