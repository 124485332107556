import React, { useEffect, useState } from "react";
import Div from "../../div";
import PreviewBox from "../../common/preview-box";
import { getAllProducts } from "../../../api/product/api";
import { Spin } from "antd";

function Product() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllProducts()
      .then((res) => {
        const data = res.data;

        data && setProducts(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <Div>
        <Spin style={{ display: "flex", justifyContent: "center" }} />
      </Div>
    );
  }

  return (
    <Div>
      <PreviewBox previewList={products} pageSize={6} type={"product"} />
    </Div>
  );
}

export default Product;
