import React, { useEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import Div from "../../div";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import DefaultImage from "../../../asset/default-image.jpg";
import ToothIcon from "../../../asset/icons/preview-box-tooth-icon.png";

function PreviewBox({ previewList, pageSize, type }) {
  const [showList, setShowList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const itemSize = previewList.length;

  useEffect(() => {
    window.scrollTo(0, 500);
    const start = (currentPage - 1) * pageSize;
    const end = start + pageSize;
    const actualEnd = Math.min(end, itemSize);

    const itemsToShow = previewList.slice(start, actualEnd);

    setShowList(pageSize ? itemsToShow : previewList);
  }, [currentPage, itemSize, pageSize, previewList]);

  return (
    <Div className="my-container py-[40px]">
      <Div className={styles.flexBox}>
        {showList.map((e, i) => {
          return (
            <Div
              key={i}
              className="group col-span-12 md:col-span-6 lg:col-span-4 overflow-hidden"
            >
              <Div
                onClick={() =>
                  navigate({
                    pathname: "/content-web",
                    search: `?${createSearchParams({
                      content: e.id,
                      type: type,
                    }).toString()}`,
                  })
                }
              >
                <Div className={styles.imageBox}>
                  <img
                    className={styles.image}
                    src={e.images[0] || DefaultImage}
                    alt=""
                    loading={true}
                  />
                  <Div className={styles.gradientBg}></Div>
                </Div>
              </Div>

              <Div className="bg-[#c1c1c133] h-full px-[15px] py-[20px]">
                <Div className="mb-[20px] text-zinc-500">
                  <p className="flex items-center gap-[5px]">
                    <span className="text-lg mb-[1px]">
                      <CiUser />
                    </span>
                    {e.supplier}
                  </p>

                  <p className="flex items-center gap-[5px]">
                    <span className="w-[18px]">
                      <img
                        className="w-full object-cover"
                        src={ToothIcon}
                        alt=""
                      />
                    </span>
                    {e.type}
                  </p>
                </Div>

                <Div
                  onClick={() =>
                    navigate({
                      pathname: "/content-web",
                      search: `?${createSearchParams({
                        content: e.id,
                        type: type,
                      }).toString()}`,
                    })
                  }
                  className="block cursor-pointer"
                >
                  <h3 className={styles.postTitle}>{e.name}</h3>
                </Div>
              </Div>
            </Div>
          );
        })}
      </Div>

      {pageSize && pageSize < itemSize && (
        <Div className="flex items-center justify-center mt-[50px]">
          <Pagination
            defaultCurrent={currentPage}
            pageSize={pageSize}
            total={itemSize}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </Div>
      )}
    </Div>
  );
}

const styles = {
  flexBox: "grid grid-cols-12 gap-[20px]",
  postTitle: "text-[17px] font-bold group-hover:text-p-color duration-700",
  imageBox: "w-full md:h-[300px] lg:h-[330px] overflow-hidden relative",
  image:
    "w-full object-cover object-center md:h-full duration-700 group-hover:scale-110 group-hover:rotate-3",
  gradientBg:
    "absolute w-full h-full top-0 left-[50%] translate-x-[-50%] bg-[#ffffff44] invisible opacity-0 group-hover:visible group-hover:opacity-100 duration-700",
};

export default PreviewBox;
