import React from "react";
import Div from "../../div";
import { GiRotaryPhone } from "react-icons/gi";
import { Link } from "react-router-dom";
import Background from "../../../asset/background/banner-ceramic.jpg";

function MiddleBanner() {
  return (
    <Div
      style={{
        backgroundImage: `linear-gradient(#6d98a977,#ffffff77),url(${Background})`,
      }}
      className={styles.bgImageContainer}
    >
      <Div className={styles.container}>
        <Div className={styles.flexBox}>
          <h3 className={styles.title}>NIỀNG RĂNG MẮC CÀI SỨ</h3>
          <p className='text-2xl font-bold md:text-3xl'>
            Thẩm mỹ - Hiệu Quả - Tiết kiệm
          </p>

          <Link to={"tel:032 903 2266"} className={styles.telBtn}>
            <Div className='text-2xl'>
              <GiRotaryPhone />
            </Div>

            <Div>Bấm gọi ngay</Div>
          </Link>
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  bgImageContainer:
    "bg-no-repeat bg-cover bg-center my-[40px] h-[300px] md:h-[400px]",
  container: "my-container h-full pt-[70px] md:pt-0",
  flexBox: "h-full flex flex-col gap-[15px] md:justify-center",
  title: "text-xl font-bold text-p-color md:text-4xl",
  telBtn:
    "flex items-center justify-center gap-[10px] w-[180px] h-[50px] rounded-full text-white text-lg font-semibold bg-p-color mt-[15px] cursor-pointer md:w-[200px] md:h-[55px]",
};

export default MiddleBanner;
