import {
  Col,
  Empty,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import { columnContact, renderStatus } from "../common/columns";
import React, { useContext, useEffect, useState } from "react";
import {
  deleteContactTypeByType,
  getAllContactsByType,
  updateStatusContactByType,
} from "../../../api/contacts/api";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} là bắt buộc`,
          },
        ]}
      >
        <Select
          placeholder="Chọn trạng thái"
          onChange={save}
          style={{
            width: "100%",
          }}
          options={[
            { value: 1, label: "Chưa tư vấn" },
            { value: 2, label: "Đã tư vấn" },
          ]}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const ContactComments = () => {
  const locale = {
    emptyText: (
      <Space direction="vertical" align="center" style={{ marginTop: "20px" }}>
        <Empty description={"Chưa có dữ liệu liên hệ"} />
      </Space>
    ),
  };

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    getAllContactsByType(5)
      .then((res) => {
        setDataSource(mapDataSource(res?.data));
      })
      .finally(() => setLoading(false));
  }

  const mapDataSource = (values) => {
    return values?.map((item) => ({
      ...item,
      key: item.id,
      createDate: dayjs(item.createdAt).format("DD-MM-YYYY"),
      status: renderStatus(item.status),
      menu: (
        <>
          <Row gutter={12} justify={"end"} align={"middle"}>
            {item.status === 2 && (
              <Col>
                <DeleteOutlined onClick={() => confirmDelete(item.id)} />
              </Col>
            )}
          </Row>
        </>
      ),
    }));
  };
  function confirmDelete(id) {
    Modal.confirm({
      title: "Xác nhận",
      content: "Bạn có chắc là muốn xóa liên hệ thắc mắc này không?",
      okText: <div style={{ color: "white" }}>Xóa</div>,
      okButtonProps: {
        className: "bg-admin-btn-primary",
      },
      onOk: () => {
        setLoading(true);
        deleteContactTypeByType(id).then((res) => {
          message.success("Xóa thành công");
          getData();
        });
      },
    });
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (row) => {
    setLoading(true);
    updateStatusContactByType(row?.id, { status: row?.status })
      .then((res) => {
        getData();
      })
      .finally(() => setLoading(false));
  };

  const columns = columnContact.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Spin tip="Xin vui lòng chờ..." spinning={loading}>
      <Table
        columns={columns}
        dataSource={dataSource}
        locale={locale}
        components={components}
        rowClassName={() => "editable-row"}
      />
    </Spin>
  );
};
export default ContactComments;
