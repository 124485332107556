import React, { useEffect, useState } from "react";
import Div from "../../div";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getAllService } from "../../../api/service/api";
import * as ServiceIcons from "./service-icons";

function DentalService({ isServicePage }) {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  const fixedServices = [
    {
      name: "Nhổ răng khôn",
      firstImage: ServiceIcons.ServiceIcon1,
      secondImage: ServiceIcons.ServiceIcon2,
      description:
        "Vì sao phải nhổ răng khôn, răng chỉ định phải nhổ khác? Cần nhổ răng khôn để tránh những biến chứng như viêm nhiễm răng lân cận, tiêu viêm xương, u nang, nhiễm trùng. Phòng tránh biến chứng khe bị giắt thức ăn giữa răng khôn và răng bên cạnh. Tương lai ảnh...",
    },
    {
      name: "Tẩy trắng răng",
      firstImage: ServiceIcons.ServiceIcon3,
      secondImage: ServiceIcons.ServiceIcon4,
      description:
        "Tẩy trắng răng như nào? Và khi nào nên tẩy trắng răng? Nhiều khách hàng có nhu cầu khi đến Nha Khoa Quốc Tế American còn băn khoăn không biết tẩy trắng răng có tốt hay không? Hay có an toàn không? hãy cùng tham khảo bài viết sau của Bác Sỹ tại Nha Khoa Quốc Tế American. Th...",
    },
    {
      name: "Trồng răng Implant",
      firstImage: ServiceIcons.ServiceIcon5,
      secondImage: ServiceIcons.ServiceIcon6,
      description:
        "Trồng răng bằng chất liệu gì? Phương pháp nào là tốt nhất, hiện đại nhất? Nha khoa ngày nay sở hữu công nghệ cấy ghép Implant là một thành tựu vĩ đại của khoa học nhân loại. Đã giúp việc thay thế những chiếc răng đã mất không còn là cơn ác mộng ngoài kỹ thuật...",
    },
    {
      name: "Trám răng - Chữa tủy",
      firstImage: ServiceIcons.ServiceIcon7,
      secondImage: ServiceIcons.ServiceIcon8,
      description:
        "Mất toàn hàm cũng là một trong những bài toán khó khi Implant chưa ra đời. Vì phục hình tháo ra lắp vào không có ưu điểm vững ổn và ngăn ngừa tình trang tiêu xương. Implant có đủ tố chất là Vững ổn, ngăn ngừa tiêu xương, cố định trên khung xương hàm bằng liên...",
    },
    {
      name: "Bọc răng sứ",
      firstImage: ServiceIcons.ServiceIcon9,
      secondImage: ServiceIcons.ServiceIcon10,
      description:
        "Bọc răng sứ mang lại giá trị thẩm mỹ cao cho hàm răng và khuôn mặt của bạn, giúp răng chống biến màu hiệu quả. Có độ bền cao, cố định trên cung hàm, phục hồi chức năng ăn nhai tốt, cải thiện giọng nói, bảo tồn răng thật...",
    },
    {
      name: "Niềng răng",
      firstImage: ServiceIcons.ServiceIcon11,
      secondImage: ServiceIcons.ServiceIcon12,
      description:
        "Niềng răng - Nắn chỉnh (hay còn gọi là niềng, cài chỉnh nha) là một khí cụ được sử dụng trong nha khoa chỉnh nha tại Nha Khoa Quốc Tế American. Để làm thẳng răng mọc khấp khểnh, đưa chúng về đúng với vị trí trên khớp cắn, đồng thời cải thiện sức khỏe răng miệng...",
    },
  ];

  useEffect(() => {
    getAllService().then((res) => {
      const data = res.data;

      data
        ? setServices(
            data.map((e, i) => {
              if (i < 6) {
                e.firstImage = fixedServices[i].firstImage;
                e.secondImage = fixedServices[i].secondImage;
                return e;
              }
            })
          )
        : setServices(fixedServices);
    });
  }, []);

  return (
    <Div>
      <Div className='my-container py-[30px]'>
        <Div className='text-center mb-[30px]'>
          <h2 className='mb-[10px] text-2xl text-p-color font-bold lg:text-4xl'>
            DỊCH VỤ NHA KHOA
          </h2>
          <p className='lg:text-lg'>
            Nơi nụ cười của bạn là một tác phẩm nghệ thuật. Làm đẹp nụ cười -
            Thay đổi cuộc sống.
          </p>
        </Div>

        <Div className='mt-[30px]'>
          <ul className='flex flex-col gap-[40px] md:flex-row md:gap-0 flex-wrap'>
            {services.map((e, i) => {
              if (e?.description?.length >= 220) {
                const truncatedDescription = e.description.slice(0, 220);
                const lastSpaceIndex = truncatedDescription.lastIndexOf(" ");
                e.description =
                  lastSpaceIndex !== -1
                    ? truncatedDescription.slice(0, lastSpaceIndex) + "..."
                    : truncatedDescription + "...";
              }

              return (
                <li key={i} className='basis-[33.3%]'>
                  <Div
                    onClick={() =>
                      navigate({
                        pathname: "/content-web",
                        search: `?${createSearchParams({
                          content: e.id,
                          type: "dental-service",
                        }).toString()}`,
                      })
                    }
                    className={styles.links}
                  >
                    {/* <Div className={styles.logoImg}>
                      <img
                        src="https://nhakhoahungcuong.com.vn/wp-content/themes/doanhnghiep/images/after_dvu_hv.png"
                        alt=""
                      />
                    </Div> */}

                    <Div className='relative h-[40px]'>
                      <img
                        className={styles.firstImg}
                        src={e?.firstImage}
                        alt=''
                      />

                      <img
                        className={styles.secondImg}
                        src={e?.secondImage}
                        alt=''
                      />
                    </Div>

                    <Div>
                      <h3 className={styles.serviceTitle}>{e?.name}</h3>

                      <Div
                        className={styles.serviceDes}
                        dangerouslySetInnerHTML={{ __html: e?.description }}
                      ></Div>
                    </Div>
                  </Div>
                </li>
              );
            })}
          </ul>

          {!isServicePage && (
            <Div className='text-center mt-[20px]'>
              <button
                onClick={() => navigate("/service")}
                className={styles.readMoreBtn}
              >
                Xem thêm
              </button>
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  links:
    "flex flex-col relative items-center gap-[20px] p-[20px] md:p-[25px] lg:p-[40px] overflow-hidden before:absolute before:content-[''] before:w-full before:h-full before:top-0 before:left-[50%] before:translate-x-[-200%] before:-z-10 before:bg-linear-color hover:before:translate-x-[-50%] before:duration-500 group cursor-pointer",
  logoImg: "absolute top-0 right-0 p-[10px]",
  firstImg:
    "max-w-[50px] object-contain absolute left-[50%] translate-x-[-50%] group-hover:invisible",
  secondImg:
    "max-w-[50px] object-contain absolute left-[50%] translate-x-[-50%] invisible group-hover:visible",
  serviceTitle:
    "text-xl text-center font-bold text-p-color mb-[10px] group-hover:text-white duration-500 lg:text-2xl",
  serviceDes:
    "text-sm group-hover:text-white duration-500 lg:text-base overflow-wrap-break-word",
  readMoreBtn:
    "pt-[7px] pb-[10px] px-[15px] border-2 border-p-color rounded-full text-p-color font-bold hover:text-white hover:bg-p-color duration-300 lg:px-[40px] lg:pt-[11px] lg:pb-[14px] lg:text-lg",
};

export default DentalService;
