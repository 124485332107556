import React, { useEffect, useState } from "react";
import Div from "../../div";
import NavigateBanner from "../../common/navigate-banner";
import ContentWebContact from "./ContentWebContact";
import { Empty } from "antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getAllNews, getNew } from "../../../api/news/api";
import { getProduct } from "../../../api/product/api";
import { getService } from "../../../api/service/api";
import { getCustomerDocument } from "../../../api/customer-documents/api";
import "./ContentWeb.css";

function ContentWeb() {
  const [content, setContent] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const contenId = searchParams.get("content");
  const type = searchParams.get("type");
  const [data, setData] = useState([]);
  if (content?.name?.includes("/")) {
    navigate("/404");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllNews().then((res) => {
      const data = res.data;
      data && setData(data);
    });
    if (contenId) {
      if (type === "news") {
        getNew(contenId).then((res) => setContent(res?.data));
      } else if (type === "product") {
        getProduct(contenId).then((res) => setContent(res.data));
      } else if (type === "dental-service") {
        getService(contenId).then((res) => setContent(res.data));
      } else if (type === "customer") {
        getCustomerDocument(contenId).then((res) => setContent(res.data));
      }
    }
  }, [type, contenId]);

  return (
    <Div>
      <section>
        {type === "product" ? (
          <NavigateBanner
            parentPage={"Sản phẩm"}
            navigatePage={content?.name}
          />
        ) : type === "dental" ? (
          <NavigateBanner parentPage={"Dịch vụ"} navigatePage={content?.name} />
        ) : type === "news" ? (
          <NavigateBanner parentPage={"Tin tức"} navigatePage={content?.name} />
        ) : (
          <NavigateBanner
            parentPage={"Khách hàng"}
            navigatePage={content?.name}
          />
        )}
      </section>
      <Div className='flex my-container'>
        <section style={{ flex: "70%" }}>
          {content ? (
            <Div className='my-container pt-[50px] pb-[30px]'>
              <Div>
                <h1
                  className='text-4xl text-p-color font-bold mb-[25px]'
                  style={{ textAlign: "center" }}
                >
                  {content?.name}
                </h1>
              </Div>

              <Div key={contenId}>
                {content?.description && (
                  <Div
                    key={contenId}
                    dangerouslySetInnerHTML={{ __html: content?.description }}
                  />
                )}
              </Div>
            </Div>
          ) : (
            <Div className='my-container pt-[50px] pb-[30px]'>
              <Div className='w-full h-[500px] flex items-center justify-center'>
                <Empty
                  style={{ fontSize: "25px" }}
                  description={"Nội dung hiện đang được cập nhật"}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </Div>
            </Div>
          )}
        </section>
        <Div className='px-[10px] pt-[50px] w-full' style={{ flex: "30%" }}>
          <Div>
            <p
              className='uppercase text-xs font-bold'
              style={{ color: "rgb(29, 72, 166)" }}
            >
              Danh mục bài viết
            </p>
            <div
              style={{
                borderBottom: "2px solid rgb(29, 72, 166)",
                width: "70px",
                padding: "2px 0",
                marginBottom: "10px",
              }}
            ></div>
          </Div>
          <Div className='px-[10px] pt-[10px] w-full border-solid border border-slate-500'>
            <ul className='flex flex-col gap-[15px]'>
              <li className='group'>
                <Div
                  className='flex items-center justify-between bg-white px-[5px] py-[12px] group-hover:bg-p-color group-hover:text-white duration-500'
                  style={{ padding: "10px" }}
                  onClick={() => navigate("/dental-product")}
                >
                  <span>Sản phẩm nha khoa</span>
                </Div>
              </li>

              <li className='group'>
                <Div
                  className='flex items-center justify-between bg-white px-[5px] py-[12px] group-hover:bg-p-color group-hover:text-white duration-500'
                  style={{ padding: "10px" }}
                  onClick={() => navigate("/news")}
                >
                  <span>Tin tức</span>
                </Div>
              </li>
            </ul>
          </Div>
          <Div style={{ marginTop: "40px " }}>
            <p
              className='uppercase text-xs font-bold'
              style={{ color: "rgb(29, 72, 166)" }}
            >
              Bài viết mới
            </p>
            <div
              style={{
                borderBottom: "2px solid rgb(29, 72, 166)",
                width: "70px",
                padding: "2px 0",
                marginBottom: "10px",
              }}
            ></div>
          </Div>
          <div className='px-[10px] pt-[10px] w-full border-solid border border-slate-500'>
            <ul className='flex flex-col gap-[15px]'>
              {data.length > 0 &&
                data.slice(0, 10).map((e, i) => {
                  return (
                    <li key={i} className='group'>
                      <div
                        onClick={() =>
                          navigate({
                            pathname: "/content-web",
                            search: `?${createSearchParams({
                              content: e.id,
                              type: "news",
                            }).toString()}`,
                          })
                        }
                        className='flex items-center justify-between bg-white px-[5px] py-[12px] group-hover:bg-p-color group-hover:text-white duration-500'
                        style={{ padding: "10px" }}
                      >
                        <span>{e.name}</span>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </Div>
      </Div>
      <section>
        <ContentWebContact />
      </section>
    </Div>
  );
}

export default ContentWeb;
