import React from "react";
import Div from "../../div";
import { useNavigate } from "react-router";
import Icon2 from "../../../asset/icons/introduce-icon-1.png";
import Icon1 from "../../../asset/icons/introduce-icon-2.png";
import Icon3 from "../../../asset/icons/introduce-icon-3.png";

function Introduce({ introImage, changeFlexOrder }) {
  const navigate = useNavigate();

  return (
    <Div className={styles.container}>
      <Div className="grid grid-cols-12 gap-[30px] lg:flex-row lg:items-start">
        <Div
          className={`flex flex-col gap-[15px] col-span-12 lg:col-span-6 ${
            changeFlexOrder && "order-2 lg:pt-[30px]"
          }`}
        >
          <h2 className="text-2xl font-bold text-p-color lg:text-4xl">
            NHA KHOA QUỐC TẾ AMERICAN
          </h2>
          <p className="pb-[25px] lg:text-lg lg:pb-[10px]">
            {changeFlexOrder
              ? "Vì sức khoẻ nụ cười của bạn"
              : "Ra đời với sứ mệnh làm đẹp nụ cười - thay đổi cuộc sống"}
          </p>
          <Div className={styles.introItems}>
            <Div className={styles.itemImgaeBox}>
              <img className={styles.itemImgae} src={Icon1} alt="" />
            </Div>

            <Div>
              <h3 className="text-[20px] font-bold pb-[5px] lg:text-[22px]">
                Đội ngũ bác sỹ tâm huyết
              </h3>

              <p className="lg:text-lg">
                Kiến thức sâu rộng và nhiều năm kinh nghiệm hoạt động trong
                ngành nha khoa thẩm mỹ
              </p>
            </Div>
          </Div>

          <Div className={styles.introItems}>
            <Div className={styles.itemImgaeBox}>
              <img className={styles.itemImgae} src={Icon2} alt="" />
            </Div>

            <Div>
              <h3 className="text-[20px] font-bold pb-[5px] lg:text-[22px]">
                Phòng khám đạt chuẩn FDA
              </h3>

              <p className="lg:text-lg">
                Hệ thống cơ sở vật chất tại phòng khám Nha khoa Quốc Tế American
                luôn được đảm bảo vô trùng
              </p>
            </Div>
          </Div>

          <Div className={styles.introItems}>
            <Div className={styles.itemImgaeBox}>
              <img className={styles.itemImgae} src={Icon3} alt="" />
            </Div>

            <Div>
              <h3 className="text-[20px] font-bold pb-[5px] lg:text-[22px]">
                Khách hàng là Trung tâm
              </h3>

              <p className="lg:text-lg">
                Mang đến những trải nghiệm trọn vẹn và an tâm nhất cho khách
                hàng. Chính sách chăm sóc và bảo hành chu đáo cho khách hàng
                suốt quá trình và sau khi điều trị
              </p>
            </Div>
          </Div>

          {!changeFlexOrder && (
            <Div>
              <button
                onClick={() => navigate("/about")}
                className={styles.readMoreBtn}
              >
                Đọc tiếp
              </button>
            </Div>
          )}
        </Div>

        <Div
          className={`col-span-12 lg:col-span-6 ${
            changeFlexOrder && "order-1"
          }`}
        >
          <img
            className="w-full lg:h-[500px] object-cover object-center"
            src={introImage}
            alt=""
          />
        </Div>
      </Div>
    </Div>
  );
}

const styles = {
  container: "my-container py-[40px] lg:py-[70px]",
  introItems: "flex gap-[20px] group [perspective:1000px] lg:mb-[20px]",
  itemImgaeBox: "min-w-[40px] max-w-[40px] md:min-w-[60px] mt-[10px]",
  itemImgae:
    "w-full duration-700 [transform-style:preserve-3d] group-hover:[transform:rotateY(360deg)]",
  readMoreBtn:
    "px-[15px] pt-[8px] pb-[10px] border-2 border-p-color rounded-full font-bold text-p-color hover:text-white hover:bg-p-color duration-500 lg:px-[25px] lg:pt-[10px] lg:pb-[13px]",
};

export default Introduce;
